import React from "react";
import Layout from "../components/Layout";
import LearningPacks2 from "../components/LearningPacks2";
export default ({ location }) => {
  return (
    <Layout>
      <LearningPacks2 subject={location.state?.subject || null} />
    </Layout>
  );
};
// ...GatsbyImageSharpFluid
