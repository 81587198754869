import React, { useState } from "react";
import Title from "./Title";
import { graphql, useStaticQuery } from "gatsby";
import { useShoppingCart } from "use-shopping-cart";
import { Carousel } from "react-responsive-carousel";

const LearningPacks2 = ({ subject }) => {
  const { addItem } = useShoppingCart();
  const query = graphql`
    {
      allStrapiLearningpacks(sort: { fields: id }) {
        nodes {
          id
          Subject
          Description
          Thumbnail {
            publicURL
          }
          SampleThumbnails {
            url
          }
          Price
          StandardPriceId
          PackagePriceId
          PackageQuantity
          Title
          OfferLabel
          file {
            publicURL
          }
          Category
        }
      }
      allStripePrice {
        nodes {
          id
          unit_amount
        }
      }
    }
  `;
  const data = useStaticQuery(query);

  const { cartDetails } = useShoppingCart();
  // When the user clicks anywhere outside of the modal, close it
  if (typeof window !== "undefined") {
    window.onclick = function (event) {
      if (event.target.id.includes("myModal")) {
        // setShowModal(false);
        setModalInfo(null);
      }
    };
  }
  const {
    allStrapiLearningpacks: { nodes: learningPacks },
    // allStrapiFiles: { nodes: files },
    allStripePrice: { nodes: prices },
  } = data;

  const [value, setValue] = useState(subject !== null ? subject : "");
  const [modalInfo, setModalInfo] = useState(null);

  let learningPackObject = {};
  for (const lp of learningPacks) {
    // if (lp.Category !== "special free") {
    if (learningPackObject[lp.Subject]) {
      learningPackObject[lp.Subject].push({
        id: lp.id,
        Description: lp.Description,
        Thumbnail: lp.Thumbnail,
        Price: lp.Category === "special free" ? 0 : prices.find(p => p.id === lp.StandardPriceId)["unit_amount"],
        PriceId: lp.StandardPriceId,
        Title: lp.Title,
        Category: lp.Category,
        PackagePrice: lp.Category === "special free" ? 0 :  prices.find(p => p.id === lp.PackagePriceId) ? prices.find(p => p.id === lp.PackagePriceId)["unit_amount"]: null,
        PackagePriceId: lp.PackagePriceId,
        PackageQuantity: lp.PackageQuantity,
        StandardPrice: lp.Category === "special free" ? 0 : prices.find(p => p.id === lp.StandardPriceId)["unit_amount"],
        StandardPriceId: lp.StandardPriceId,
        OfferLabel: lp.OfferLabel,
        SampleThumbnails: lp.SampleThumbnails,
        loc: lp.file.publicURL,
      });
    } else {
      learningPackObject[lp.Subject] = [
        {
          id: lp.id,
          Description: lp.Description,
          Thumbnail: lp.Thumbnail,
          // Price: lp.Price,
          Price: lp.Category === "special free" ? 0 : prices.find(p => p.id === lp.StandardPriceId)["unit_amount"],
          PriceId: lp.Category === "special free" ? null : lp.StandardPriceId,
          Title: lp.Title,
          Category: lp.Category,
          PackagePrice: lp.Category === "special free" ? 0 : prices.find(p => p.id === lp.PackagePriceId) ? prices.find(p => p.id === lp.PackagePriceId)["unit_amount"]: null,
          PackagePriceId: lp.PackagePriceId,
          PackageQuantity: lp.PackageQuantity,
          StandardPrice: lp.Category === "special free" ? 0 : prices.find(p => p.id === lp.StandardPriceId)["unit_amount"],
          StandardPriceId: lp.StandardPriceId,
          OfferLabel: lp.OfferLabel,
          SampleThumbnails: lp.SampleThumbnails,
          loc: lp.file.publicURL,
        },
      ];
    }
    // }
  }
  if (!value) {
    setValue(Object.keys(learningPackObject)[0]);
  }

  const learningPackArray = value ? learningPackObject[value] : learningPackObject[Object.keys(learningPackObject)[0]];

  const buyItem = learningPack => {
    const learningPackInBasket = Object.values(cartDetails).find(lp => lp.packagePriceId == learningPack.PackagePriceId);

    if (learningPackInBasket) {
      alert(`Learning pack: ${learningPack.Title} is already in your basket`);
    } else {
      addItem(
        {
          name: learningPack.Title,
          id: learningPack.PriceId,
          price: learningPack.Price,
          currency: "GBP",
          category: learningPack.Category,
          packagePrice: learningPack.PackagePrice,
          packagePriceId: learningPack.PackagePriceId,
          packageQuantity: learningPack.PackageQuantity,
          standardPrice: learningPack.StandardPrice,
          standardPriceId: learningPack.StandardPriceId,
          loc: learningPack.loc,
        },
        1
      );
      // alert(`Learning pack: ${learningPack.Title} has been added to the basket`);
    }
  };
  return (
    <div className="blog-page">
      <section className="section jobs bg-grey">
        <Title title="Resources" />
        <div className="section-center jobs-center">
          <div className="btn-container">
            {Object.keys(learningPackObject).map((learningCategory, index) => (
              <a key={index} onClick={() => setValue(learningCategory)} className={`job-btn ${learningCategory === value && "active-btn"}`}>
                {learningCategory}
              </a>
            ))}
          </div>
          <div className="job-info-container">
            {learningPackArray.map((learningPack, index) => (
              <article className="job-info">
                <div className="card">
                  <div className="pack-container">
                    <div className={`colour-orange ${learningPack.OfferLabel ? "offer-colour" : "offer-white"}`}>{learningPack.OfferLabel || "thing"}</div>
                    <div className="pack-title" style={{ marginBottom: "5px" }}>
                      <strong>{learningPack.Title}</strong>
                    </div>
                    <img src={learningPack.Thumbnail.publicURL} alt={learningPack.Title} className="learning-pack-image" height="170px" width="100%" />
                    <div className="pack-info">
                      <div>
                        <button
                          className="btn info-button"
                          onClick={() => {
                            setModalInfo(
                              <div id={`myModal${learningPack.id}`} className="modal">
                                <div className="modal-content">
                                  <span
                                    className="close"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      // width: "100%",
                                    }}
                                    onClick={() => setModalInfo(null)}
                                  >
                                    &times;
                                  </span>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <h3>{learningPack.Title}</h3>
                                    {learningPack.SampleThumbnails?.length ? (
                                      <div className="custom-modal-wrapper">
                                        <Carousel>
                                          {learningPack.SampleThumbnails.map((st, index) => (
                                            <div>
                                              <img src={st.url} alt="" />
                                            </div>
                                          ))}
                                        </Carousel>
                                      </div>
                                    ) : (
                                      <img
                                        src={learningPack.Thumbnail.publicURL}
                                        height="200px"
                                        className="modal-img"
                                        style={{ marginBottom: "10px" }}
                                        alt={learningPack.Subject}
                                      />
                                    )}

                                    <div className="learning-pack-description">
                                      <pre>{learningPack.Description}</pre>
                                      <div className="modal-buttons">
                                        <div>
                                          <button className="btn price-button modal-price" style={{ marginTop: "5px" }}>
                                            £{(learningPack.Price / 100).toFixed(2)}
                                          </button>
                                        </div>

                                        <button className="btn buy-button" style={{ marginTop: "5px" }} onClick={() => setModalInfo(null)}>
                                          Close
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                            // setShowModal(true);
                          }}
                        >
                          Info
                        </button>
                      </div>
                      <div>
                        <button className="btn price-button">£{(learningPack.Price / 100).toFixed(2)}</button>
                      </div>
                      <div className="w-100">
                        <button className="btn buy-button w-100" onClick={() => buyItem(learningPack, 1)}>
                          Add to cart
                        </button>
                      </div>
                    </div>
                  </div>
                  {modalInfo && modalInfo}
                </div>
              </article>
            ))}
          </div>
          {/* <div>
              <a href={files[0].filename.publicURL} target="_blank" download>
                Download
              </a>
            </div> */}
        </div>
      </section>
    </div>
  );
};

export default LearningPacks2;
